/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { baseInput } from '../../elements/input/styles';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import TickWhite from '../../../static/images/tick-circle-white.svg';

const StyledContainer = styled.div`
  max-width: 900px;
  margin: 30px auto;
  padding: 0px 40px;

  @media (max-width: ${props => props.theme.components.phoneWidthMax}) {
    padding: 0px 30px;
  }

  ${props =>
    props.background !== '' &&
    css`
      padding: 40px;

      @media (max-width: ${props => props.theme.components.phoneWidthMax}) {
        padding: 30px;
      }
    `}

  ${props =>
    props.background === 'White' &&
    css`
      background-color: #ffffff;
    `}

  ${props =>
    props.background === 'Red' &&
    css`
      background-color: ${props.theme.colours.nimbleRed};
    `}

  ${props =>
    props.background === 'Pink' &&
    css`
      background-color: ${props.theme.colours.pink};
    `}

  ${props =>
    props.background === 'Grey' &&
    css`
      background-color: ${props.theme.colours.greyBack};
    `}

  ${props =>
    props.background === 'Cream' &&
    css`
      background-color: ${props.theme.colours.cream};
    `}

  ${props =>
    props.background === 'Green' &&
    css`
      background-color: ${props.theme.colours.lightGreen};
    `}
`;

const StyledHeading = styled.h2`
  margin-bottom: 30px;
`;

const FieldRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    grid-template-columns: 1fr;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: ${props => props.theme.size.large};
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  ${baseInput}
`;

const StyledTextarea = styled.textarea`
  ${baseInput}
`;

const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-top: 15px;
  margin-bottom: 8px;
`;

const RadioLabel = styled.span`
  padding-left: 8px;
`;

const SelectWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 40%;
    right: 20px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.theme.colours.slateLight};
    width: 0px;
    height: 0px;
    transition: all 0.35s ease 0s;
  }
`;

const StyledSelect = styled.select`
  ${baseInput}
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.colours.red};
  margin: 0px;
  font-size: 14px;
`;

const SuccessContainer = styled.div`
  color: ${props => props.theme.colours.green};
  font-size: 20px;
  margin: 30px 0px;
  text-align: center;
`;

const SuccessTick = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.colours.green};
  padding: 20px;
  background-image: url('${TickWhite}');
  background-position: center;
  background-size: 40px 40px;
  box-sizing: initial;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
`;

const ErrorContainer = styled.div`
  color: ${props => props.theme.colours.red};
  margin: 30px 0px;
`;

const formSchema = z.object({
  first_name: z.string().min(1, { message: 'Please enter your first name.' }),
  last_name: z.string().min(1, { message: 'Please enter your last name.' }),
  email: z
    .string()
    .min(1, { message: 'Please enter your email address.' })
    .email('Please enter a valid email address.'),
  phone: z
    .string()
    .regex(/^(\+61\d{9}|04\d{8})$/, 'Please enter a valid mobile.'),
  like_to_pay: z.coerce
    .number({ invalid_type_error: 'Must be a number' })
    .gt(1, `Please enter an amount`)
    .step(0.01, `Please enter a proper currency amount`)
    .nonnegative(`Please enter a proper currency amount`),
  payment_frequency: z.string({
    errorMap: (issue, { defaultError }) => ({
      message:
        issue.code === 'invalid_type'
          ? 'Please choose a frequency.'
          : defaultError
    })
  }),
  method_of_payment: z.string({
    errorMap: (issue, { defaultError }) => ({
      message:
        issue.code === 'invalid_type'
          ? 'Please choose a payment method.'
          : defaultError
    })
  }),
  current_income_amount: z.coerce
    .number({ invalid_type_error: 'Must be a number' })
    .gt(1, `Please enter an amount`)
    .step(0.01, `Please enter a proper currency amount`)
    .nonnegative(`Please enter a proper currency amount`),
  income_frequency: z.string({
    errorMap: (issue, { defaultError }) => ({
      message:
        issue.code === 'invalid_type'
          ? 'Please choose a frequency.'
          : defaultError
    })
  }),
  rent_board_mortgage_amount: z.coerce
    .number({ invalid_type_error: 'Must be a number' })
    .gt(1, `Please enter an amount`)
    .step(0.01, `Please enter a proper currency amount`)
    .nonnegative(`Please enter a proper currency amount`),
  bill_frequency: z.string({
    errorMap: (issue, { defaultError }) => ({
      message:
        issue.code === 'invalid_type'
          ? 'Please choose a frequency.'
          : defaultError
    })
  }),
  electricity_per_month: z.coerce
    .number({ invalid_type_error: 'Must be a number' })
    .gt(1, `Please enter an amount`)
    .step(0.01, `Please enter a proper currency amount`)
    .nonnegative(`Please enter a proper currency amount`),
  water_per_month: z.coerce
    .number({ invalid_type_error: 'Must be a number' })
    .gt(1, `Please enter an amount`)
    .step(0.01, `Please enter a proper currency amount`)
    .nonnegative(`Please enter a proper currency amount`),
  fuel_transport_weekly: z.coerce
    .number({ invalid_type_error: 'Must be a number' })
    .gt(1, `Please enter an amount`)
    .step(0.01, `Please enter a proper currency amount`)
    .nonnegative(`Please enter a proper currency amount`),
  groceries_per_week: z.coerce
    .number({ invalid_type_error: 'Must be a number' })
    .gt(1, `Please enter an amount`)
    .step(0.01, `Please enter a proper currency amount`)
    .nonnegative(`Please enter a proper currency amount`),
  other_expenses: z.string().optional(),
  debts: z.string().optional(),
  other_important_information: z.string().optional()
});

export default function HardshipForm({ data }) {
  const {
    hashId,
    componentBackground,
    heading,
    formSpreeLink,
    reCaptchaSiteKey,
    submitButton,
    successMessage,
    errorMessage
  } = data;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: zodResolver(formSchema)
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = data => {
    grecaptcha.ready(function() {
      grecaptcha
        .execute(reCaptchaSiteKey, { action: 'submit' })
        .then(function(token) {
          const formData = new FormData(
            document.getElementById(`hardshipform-${hashId}`)
          );

          formData.append('g-recaptcha-response', token);

          fetch(formSpreeLink, {
            method: 'POST',
            body: formData,
            headers: {
              Accept: 'application/json'
            }
          })
            .then(response => response.json())
            .then(response => {
              if (response.ok && response.next) {
                setSuccess(true);
                setError(false);

                setTimeout(() => {
                  const errorPosition = document.getElementById(`hardshipform-success-${hashId}`).offsetTop;
                  window.scrollTo({
                    top: errorPosition - 180,
                    behavior: 'smooth'
                  });
                }, 150);
              } else {
                setError(true);
                setSuccess(false);
              }
            })
            .catch(() => {
              setError(true);
              setSuccess(false);
            });
        });
    });
  };

  useEffect(() => {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`
    );
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    document.head.appendChild(recaptchaScript);
  }, []);

  return (
    <div id={hashId}>
      <StyledContainer background={componentBackground}>
        {heading && <StyledHeading>{heading}</StyledHeading>}

        {/* Success */}
        {success ? (
          <SuccessContainer id={`hardshipform-success-${hashId}`}>
            <SuccessTick />
            {successMessage}
          </SuccessContainer>
        ) : (
          <form id={`hardshipform-${hashId}`} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-fields">
              <FieldRow>
                <FieldContainer>
                  <StyledLabel htmlFor="firstName">First name*</StyledLabel>
                  <StyledInput
                    id="firstName"
                    name="first_name"
                    type="text"
                    {...register('first_name')}
                  />
                  {errors.first_name && (
                    <ErrorMessage>{errors.first_name.message}</ErrorMessage>
                  )}
                </FieldContainer>
                <FieldContainer>
                  <StyledLabel htmlFor="lastName">Last name*</StyledLabel>
                  <StyledInput
                    id="lastName"
                    name="last_name"
                    type="text"
                    {...register('last_name')}
                  />
                  {errors.last_name && (
                    <ErrorMessage>{errors.last_name.message}</ErrorMessage>
                  )}
                </FieldContainer>
              </FieldRow>
              <FieldRow>
                <FieldContainer>
                  <StyledLabel htmlFor="email">Email*</StyledLabel>
                  <StyledInput
                    id="email"
                    name="email"
                    type="email"
                    {...register('email')}
                  />
                  {errors.email && (
                    <ErrorMessage>{errors.email.message}</ErrorMessage>
                  )}
                </FieldContainer>
                <FieldContainer>
                  <StyledLabel htmlFor="phone">Phone*</StyledLabel>
                  <StyledInput
                    id="phone"
                    name="phone"
                    type="tel"
                    {...register('phone')}
                  />
                  {errors.phone && (
                    <ErrorMessage>{errors.phone.message}</ErrorMessage>
                  )}
                </FieldContainer>
              </FieldRow>
              <FieldContainer>
                <StyledLabel htmlFor="idliketopay">
                  I'd like to pay*
                </StyledLabel>
                <StyledInput
                  id="idliketopay"
                  name="like_to_pay"
                  type="number"
                  {...register('like_to_pay')}
                />
                {errors.like_to_pay && (
                  <ErrorMessage>{errors.like_to_pay.message}</ErrorMessage>
                )}
              </FieldContainer>
              <FieldContainer>
                <StyledLabel htmlFor="illpaythis">I'll pay this*</StyledLabel>
                <RadioGroup role="group">
                  <label>
                    <input
                      type="radio"
                      id="illpaythis"
                      name="payment_frequency"
                      value="Weekly"
                      {...register('payment_frequency')}
                    />
                    <RadioLabel>Weekly</RadioLabel>
                  </label>
                  <label>
                    <input
                      type="radio"
                      id="illpaythis"
                      name="payment_frequency"
                      value="Fortnightly"
                      {...register('payment_frequency')}
                    />
                    <RadioLabel>Fortnightly</RadioLabel>
                  </label>
                  <label>
                    <input
                      type="radio"
                      id="illpaythis"
                      name="payment_frequency"
                      value="Monthly"
                      {...register('payment_frequency')}
                    />
                    <RadioLabel>Monthly</RadioLabel>
                  </label>
                </RadioGroup>
                {errors.payment_frequency && (
                  <ErrorMessage>
                    {errors.payment_frequency.message}
                  </ErrorMessage>
                )}
              </FieldContainer>
              <FieldContainer>
                <StyledLabel htmlFor="illpayby">I'll pay this by*</StyledLabel>
                <RadioGroup role="group">
                  <label>
                    <input
                      type="radio"
                      id="illpayby"
                      name="method_of_payment"
                      value="Direct debit from my account"
                      {...register('method_of_payment')}
                    />
                    <RadioLabel>Direct debit from my account</RadioLabel>
                  </label>
                  <label>
                    <input
                      type="radio"
                      id="illpayby"
                      name="method_of_payment"
                      value="Manual Transfer"
                      {...register('method_of_payment')}
                    />
                    <RadioLabel>Manual Transfer</RadioLabel>
                  </label>
                  <label>
                    <input
                      type="radio"
                      id="illpayby"
                      name="method_of_payment"
                      value="Other"
                      {...register('method_of_payment')}
                    />
                    <RadioLabel>Other</RadioLabel>
                  </label>
                </RadioGroup>
                {errors.method_of_payment && (
                  <ErrorMessage>
                    {errors.method_of_payment.message}
                  </ErrorMessage>
                )}
              </FieldContainer>
              <FieldRow>
                <FieldContainer>
                  <StyledLabel htmlFor="currentIncomeAmount">
                    I currently receive (please enter amount after tax)*
                  </StyledLabel>
                  <StyledInput
                    id="currentIncomeAmount"
                    name="current_income_amount"
                    type="text"
                    {...register('current_income_amount')}
                  />
                  {errors.current_income_amount && (
                    <ErrorMessage>
                      {errors.current_income_amount.message}
                    </ErrorMessage>
                  )}
                </FieldContainer>
                <FieldContainer>
                  <StyledLabel htmlFor="incomeFrequency">
                    Pay Frequency*
                  </StyledLabel>
                  <RadioGroup role="group">
                    <label>
                      <input
                        type="radio"
                        id="incomeFrequency"
                        name="income_frequency"
                        value="Weekly"
                        {...register('income_frequency')}
                      />
                      <RadioLabel>Weekly</RadioLabel>
                    </label>
                    <label>
                      <input
                        type="radio"
                        id="incomeFrequency"
                        name="income_frequency"
                        value="Fortnightly"
                        {...register('income_frequency')}
                      />
                      <RadioLabel>Fortnightly</RadioLabel>
                    </label>
                    <label>
                      <input
                        type="radio"
                        id="incomeFrequency"
                        name="income_frequency"
                        value="Monthly"
                        {...register('income_frequency')}
                      />
                      <RadioLabel>Monthly</RadioLabel>
                    </label>
                  </RadioGroup>
                  {errors.income_frequency && (
                    <ErrorMessage>
                      {errors.income_frequency.message}
                    </ErrorMessage>
                  )}
                </FieldContainer>
              </FieldRow>
              <FieldRow>
                <FieldContainer>
                  <StyledLabel htmlFor="rentBoard">
                    How much do you pay for rent/board/mortgage?*
                  </StyledLabel>
                  <StyledInput
                    id="rentBoard"
                    name="rent_board_mortgage_amount"
                    type="text"
                    {...register('rent_board_mortgage_amount')}
                  />
                  {errors.rent_board_mortgage_amount && (
                    <ErrorMessage>
                      {errors.rent_board_mortgage_amount.message}
                    </ErrorMessage>
                  )}
                </FieldContainer>
                <FieldContainer>
                  <StyledLabel htmlFor="billFrequency">I pay this*</StyledLabel>
                  <RadioGroup role="group">
                    <label>
                      <input
                        type="radio"
                        id="billFrequency"
                        name="bill_frequency"
                        value="Weekly"
                        {...register('bill_frequency')}
                      />
                      <RadioLabel>Weekly</RadioLabel>
                    </label>
                    <label>
                      <input
                        type="radio"
                        id="billFrequency"
                        name="bill_frequency"
                        value="Fortnightly"
                        {...register('bill_frequency')}
                      />
                      <RadioLabel>Fortnightly</RadioLabel>
                    </label>
                    <label>
                      <input
                        type="radio"
                        id="billFrequency"
                        name="bill_frequency"
                        value="Monthly"
                        {...register('bill_frequency')}
                      />
                      <RadioLabel>Monthly</RadioLabel>
                    </label>
                  </RadioGroup>
                  {errors.bill_frequency && (
                    <ErrorMessage>{errors.bill_frequency.message}</ErrorMessage>
                  )}
                </FieldContainer>
              </FieldRow>
              <FieldRow>
                <FieldContainer>
                  <StyledLabel htmlFor="electricityPerMonth">
                    How much do you pay for electricity per month?*
                  </StyledLabel>
                  <StyledInput
                    id="electricityPerMonth"
                    name="electricity_per_month"
                    type="text"
                    {...register('electricity_per_month')}
                  />
                  {errors.electricity_per_month && (
                    <ErrorMessage>
                      {errors.electricity_per_month.message}
                    </ErrorMessage>
                  )}
                </FieldContainer>
                <FieldContainer>
                  <StyledLabel htmlFor="waterPerMonth">
                    How much do you pay for water per month?*
                  </StyledLabel>
                  <StyledInput
                    id="waterPerMonth"
                    name="water_per_month"
                    type="text"
                    {...register('water_per_month')}
                  />
                  {errors.water_per_month && (
                    <ErrorMessage>
                      {errors.water_per_month.message}
                    </ErrorMessage>
                  )}
                </FieldContainer>
              </FieldRow>
              <FieldRow>
                <FieldContainer>
                  <StyledLabel htmlFor="fuelTransportWeekly">
                    How much do you pay for fuel and transport per week?*
                  </StyledLabel>
                  <StyledInput
                    id="fuelTransportWeekly"
                    name="fuel_transport_weekly"
                    type="text"
                    {...register('fuel_transport_weekly')}
                  />
                  {errors.fuel_transport_weekly && (
                    <ErrorMessage>
                      {errors.fuel_transport_weekly.message}
                    </ErrorMessage>
                  )}
                </FieldContainer>
                <FieldContainer>
                  <StyledLabel htmlFor="groceriesPerWeek">
                    How much do you pay for food and groceries per week?*
                  </StyledLabel>
                  <StyledInput
                    id="groceriesPerWeek"
                    name="groceries_per_week"
                    type="text"
                    {...register('groceries_per_week')}
                  />
                  {errors.groceries_per_week && (
                    <ErrorMessage>
                      {errors.groceries_per_week.message}
                    </ErrorMessage>
                  )}
                </FieldContainer>
              </FieldRow>

              <FieldContainer>
                <StyledLabel htmlFor="otherExpenses">
                  Please list other expenses and how often you pay these
                </StyledLabel>
                <StyledTextarea
                  id="otherExpenses"
                  name="other_expenses"
                  rows="5"
                  wrap="on"
                  {...register('other_expenses')}
                />
                {errors.other_expenses && (
                  <ErrorMessage>{errors.other_expenses.message}</ErrorMessage>
                )}
              </FieldContainer>
              <FieldContainer>
                <StyledLabel htmlFor="debts">
                  Please list your current debts, how much you pay and how often
                  you pay these
                </StyledLabel>
                <StyledTextarea
                  id="debts"
                  name="debts"
                  rows="5"
                  wrap="on"
                  {...register('debts')}
                />
                {errors.debts && (
                  <ErrorMessage>{errors.debts.message}</ErrorMessage>
                )}
              </FieldContainer>
              <FieldContainer>
                <StyledLabel htmlFor="otherImportantInformation">
                  Please tell us any other important information you couldn't
                  include above.
                </StyledLabel>
                <StyledTextarea
                  id="otherImportantInformation"
                  name="other_important_information"
                  rows="5"
                  wrap="on"
                  {...register('other_important_information')}
                />
                {errors.other_important_information && (
                  <ErrorMessage>
                    {errors.other_important_information.message}
                  </ErrorMessage>
                )}
              </FieldContainer>
            </div>

            {Object.keys(errors).length > 0 && (
              <ErrorMessage>
                Please fix any errors before continuing
              </ErrorMessage>
            )}
            {/* Button */}
            <div className="buttonWrap">
              <input
                type="submit"
                className="button btn-green"
                value={submitButton}
                disabled={isSubmitting}
              />
            </div>

            {/* Error */}
            {error && <ErrorContainer>{errorMessage}</ErrorContainer>}
          </form>
        )}
      </StyledContainer>
    </div>
  );
}

HardshipForm.propTypes = {
  data: PropTypes.shape({
    hashId: PropTypes.string,
    subjects: PropTypes.arrayOf(PropTypes.string),
    submitButton: PropTypes.string,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    contactDetailsBackground: PropTypes.string,
    contactDetailsCol1: PropTypes.string,
    contactDetailsCol2: PropTypes.string
  })
};
